.loaction-header {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
}

.text1 {
  color: #11de8a;
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 20px;
  text-align: center;
}

.mainDiv1 {
  background-color: #11de8a;
  height: 100%;
  width: 100%;
  flex: 100%;
}

.card-Login1 {
  margin:50px auto!important;
  max-width: 525px !important;
  height: 440px;
  background-color: #f8f9fb !important;
  padding: 25px;
}

.card-Login1Error {
  margin-top: 30px;
  width: 525px;
  height: 466px;
  background-color: #f8f9fb !important;
  padding: 25px;
}

.card-Login2 {
  margin:50px auto!important;
  max-width: 525px !important;
  height: 413px;
  background-color: #f8f9fb !important;
  padding: 25px;
}

.port-Head {
  margin-left: auto;
  margin-right: auto;
}

.homeText {
  font-size: 17px !important;
  color: white;
  font-weight: 550 !important;
}

.port-text {
  color: white;
  font-weight: bold;
}

.emailLabel {
  font-weight: bold;
  color: black;
}

.passwordLabel {
  font-weight: bold;
  margin-top: 5px;
  color: black;
}

.forgetText11 {
  font-size: 12px;
  margin-top: -62px;
  color: #11de8a;
  text-decoration: underline;
  float: right;
  font-weight: 600;
}

.forgetPassRow {
  justify-content: end;
}

.loginButton {
  width: 100%;
  border-radius: 8px !important;
}

.sendEmailBtn {
  border-radius: 8px !important;
  margin-bottom: 5px !important;
}

.signupButton {
  width: 360px;
  border-radius: 8px !important;
}

.btnDivL {
  /* margin-top: -140px; */
  margin-bottom: 9px;
}

.btnDivL1 {
  margin-top: 10px;
  margin-bottom: 9px;
}

.btnDiv {
  margin-top: -125px;
  margin-bottom: 9px;
}

.card-Signup1 {
  margin-top: 20px;
  /* width: 400px; */
  height: 480px;
  background-color: #f8f9fb !important;
}

.text1v {
  color: #11de8a;
  font-weight: 600;
  font-size: 25px;
  text-align: center;
}

.pinText1 {
  font-size: 10px;
  text-align: center;
  margin-bottom: 0;
  padding: 0 46px;
  font-weight: 600;
}

.card-Verification1 {
  margin:50px auto!important;
  max-width: 525px !important;
  height: 413px;
  background-color: #f8f9fb !important;
  padding: 25px;
}

.btnDivV {
  margin-top: -85px;
  margin-bottom: 9px;
}

.sendCode {
  font-size: 11px;
  color: #11de8a;
  text-decoration: underline;
  margin-top: 25px;
  text-align: center;
}

.imgPosition {
  margin-top: 40px;
}

.imgPosition1 {
  margin-top: -240px !important;
  position: absolute;
  background-color: white;
}

.forgetText1 {
  color: #11de8a;
  font-weight: 600;
  font-size: 22px;
  text-align: center;
}

.testtt1 {
  margin-top: 20px;
  margin-left: 470px;
}

.signinButton {
  margin-top: 10px;
  border-radius: 8px !important;
  background-color: #fff !important;
  color: #11de8a !important;
}

.privTitle1 {
  font-weight: bold;
  font-size: 30px;
  margin: 10px 30px 10px 30px;
}

.privDiv1 {
  border: 1px solid;
  border-color: #abafb3 !important;
  border-radius: 20px;
  padding: 60px;
  padding-left: 50px;
  background-color: white;
  flex-direction: row;
  height: 500px;
  margin-right: 40px;
  margin-left: 40px;
  overflow: auto
}

.rectangleComponent {
  width: 8px;
  height: 320px;
  background-color: #11de8a;
  margin-left: -50px;
  border-radius: 10px;
  position: absolute;
}

.navbarStyle {
  width: 100%;
  height: 20px !important;
}

.emailDiv {
  justify-content: center !important;
  flex-direction: row;
}

.emailText {
  color: white;
  font-size: 17px !important;
  margin-left: -50px !important;
}

.EmailNavItem {
  color: green;
  font-size: 17px !important;
}

.btn-round1 {
  border-radius: 90px !important;
  padding-right: 23px;
  padding-left: 23px;
  background-color: #ff5928 !important;
  font-size: 12px !important;
  height: 40px !important;
}

.sideImage1 {
  margin-top: 40px;
}

.sideHead1 {
  font-size: 20px;
  color: white;
  background-color: green;
  font-weight: 600;
  margin-top: 30px !important;
  margin-left: 55px !important;
}

.sideHead2 {
  font-size: 5px !important;
}

.PlanTopDiv1 {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.plantextRow {
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 20px;

}

.planSelectText {
  font-size: 19px;
  color: white;
  font-weight: bold;
  margin: 0;
}

.selectPlanCard {
  margin-top: 20px !important;
  height: 500px;
}

@media screen and (max-width:1200px) {
  .selectPlanCard {
    height: 530px;
  }
}

@media screen and (max-width:768px) {
  .selectPlanCard {
    height: 730px;
  }
}

.selectPlanCard1 {
  margin-top: 20px !important;
}

/* .selectPlanCard11 {
    width:max-content !important;
  } */

/* .selectPlanDiv {
      margin-left: 420px;
  } */

.packageTypeText {
  font-size: 16px;
  color: white;
  font-weight: 700;
  text-align: center;
}

.PackageNameHead1 {
  width: 100% !important;
  background-color: #ff5928 !important;
  height: 55px !important;
  border-bottom-left-radius: 0px;
  padding-left: 40px !important;
  border-top-right-radius: 7px !important;
  border-top-left-radius: 7px !important;
}

.PackageNameHead11 {
  width: 100% !important;
  background-color: #ff5928 !important;
  border-bottom-left-radius: 0px;
  text-align: center !important;
  border-top-right-radius: 7px !important;
  border-top-left-radius: 7px !important;
  height: 55px;
}

.PackageNameHead2 {
  width: 100% !important;
  background-color: #00cf78 !important;
  height: 55px !important;
  border-bottom-left-radius: 0px;
  padding-left: 30px !important;
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
  justify-content: center !important;
}

.PackageNameHead22 {
  width: 100% !important;
  background-color: #00cf78 !important;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
  text-align: center !important;
  height: 55px;
}

.firstRow {
  justify-content: center;
}

.firstRow1 {
  justify-content: center;
}

.secondRow {
  justify-content: center;
}

.CircleIcon {
  color: #ff5928 !important;
}

.CircleIconSuccess {
  color: #28ff70 !important;
}

.cardBody1 {
  padding: 40px !important;
  padding-left: 60px !important;
  border-bottom-right-radius: 3px !important;
}

.GreenBtn {
  width: 180px;
  margin-left: -22px !important;
  margin-top: 18px !important;
  margin-bottom: -10px !important;
  background-color: #00cf78 !important;
  border-radius: 10px !important;
}

.OrangeBtn {
  width: 180px;
  margin-left: -22px !important;
  margin-top: 18px !important;
  margin-bottom: -10px !important;
  background-color: #ff5928 !important;
  border-radius: 10px !important;
}

.BasicPackgage2 {
  justify-content: center;
}

.BasicPackageHeaderTitle {
  justify-content: center !important;
}

.addCardText {
  color: #11de8a;
  font-weight: 900;
  font-size: 20px;
  margin-top: 20px;
}

.CardNumber1 {
  font-size: 16px;
  margin: 14px 0;
}

.inp1 {
  height: 45px !important;
  border-radius: 15px !important;
}

.CardNumber2 {
  font-size: 12 px;
  margin-top: 12px;
}

.cardDetailDiv {
  margin-top: 10px;
}

.lastButton {
  justify-content: center;
}

.ConfirmBtn {
  border-radius: 10px !important;
  background-color: #ff5928 !important;
}

.tabBtn {
  background-color: #ff5928 !important;
}

.ConfirmBtn2 {
  border-radius: 10px !important;
  background-color: #00cf78 !important;
}

.CardNumber3 {
  font-size: 12 px;
  margin-top: 12px;
  margin-right: 0px !important;
}

.sidebar-wrapper11 {
  position: relative;
  height: 100%;
  overflow: auto;
  width: 260px;
  z-index: 4;
  padding-bottom: 100px;
  background-color: #11de8a;
}

.navbar-green {
  background-color: #11de8a;
}

.NavRow1 {
  justify-content: center !important;
}

.PackageNameHead4 {
  width: 100% !important;
  background-color: #00cf78 !important;
  height: 55px !important;
  border-bottom-left-radius: 0px;
  padding-left: 40px !important;
  border-top-right-radius: 7px !important;
  border-top-left-radius: 7px !important;
}

.AddBusinessIconRow {
  justify-content: center !important;
}

.backImage {
  width: 15px;
  margin-top: -7px;
  margin-Right: 20px;
}

.MainBusinessDiv {
  border: 1px solid;
  border-color: lightgray;
  margin-left: 10px;
  margin-top: 60px ;
  position: relative;
  border-radius: 10px;
  padding: 20px;
  padding-top: 150px;
}

.MainBusinessDiv2 {
  border: 1px solid;
  border-color: lightgray;
  margin-left: 10px;
  margin-top: 10px !important;
  position: relative;
  border-radius: 10px;
  padding: 30px;
  padding-top: 50px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.BusinessRow1 {
  justify-content: center;
  margin-top: 18px;
  display: flex;
}

.BusinessRowEmail {
  justify-content: center;
  margin-top: 40px;
  display: flex;
}

.BusinessRow1Address {
  justify-content: center;
  margin-top: 18px;
  display: flex;
}

.BusinessRow1CityZip {
  justify-content: center;
  margin-top: 30px;
  display: flex;
}

.BusinessRow3 {
  margin-top: -22px;
  border: 1px solid;
  border-color: lightgray;
  padding-bottom: 30px;
  margin-left: 5px;
  width: 100%;
  padding-top: 50px;
}

.BusinessInp {
  height: 51px !important;
  border-radius: 10px !important;
}

.BusinessInps {
  height: 51px !important;
  border-radius: 10px !important;
  padding-right: 20px !important;
}

.BusinessImageBtn {
  border-radius: 15px !important;
  background-color: #11de8a !important;
  height: 40px;
  /* margin-top: 5px !important; */
  font-size: 11px !important;
}

.BusinessAddBtn {
  color: #11de8a !important;
  border-radius: 12px !important;
  background-color: #f2fff9 !important;
}

.BusinessRow2 {
  justify-content: center;
  margin: top -10px;
}

.BusinessTick {
  color: white;
  size: 20px;
}

.SaveBtn {
  width: 100% !important;
  border-radius: 10px !important;
  background-color: #11de8a !important;
}

.finalBtnDiv {
  margin-bottom: 15px;
}

.imgRow1 {
  justify-content: center;
}

.SaveButton13 {
  width: 100% !important;
  border-radius: 10px !important;
  background-color: #11de8a !important;
  margin-top: 20px !important;
}

.BussinessRow4 {
  justify-content: center !important;
}

.PasswordChangetext2 {
  font-weight: 700;
  text-decoration: underline;
  margin-left: 15px;
}

.BusinessChangeBtn1 {
  background-color: white !important;
  border-radius: 10px !important;
  color: #00cf78 !important;
  border: 1px solid !important;
  border-color: #00cf78 !important;
  margin-left: 15px !important;
}

.divBorderSetting {
  border-left: 10px solid #00cf78 !important;
  padding-left: 30px;
  border-radius: 10px;
}

.removeLabelBold {
  font-weight: bold !important;
  color: black !important;
  font-size: 10px !important;
  margin-top: 10px !important;
}

.imageMargin11 {
  margin-left: 5px !important;
}

.tableIconSize {
  font-size: 18px !important;
}

.bellIconWeight1 {
  font-weight: 700 !important;
  margin-top: 10px;
}

.changePasswordInput {
  width: 200px;
}

.modalBtnStyle {
  height: 25px !important;
  width: 25px !important;
}

.borderRight {
  border-right: gray 1px solid;
  height: 50px;
  padding-top: 15px;
}

.cardNumm {
  padding-top: 15px;
}

.creditCardStyles {
  height: 80px;
  border: lightgray 1px solid !important;
}

.creditCardStyles2 {
  height: 80px;
  border: #11de8a 3px solid !important;
}

.nocardtext {
  justify-content: center !important;
  align-items: center !important;
}

.selectPackageBtn {
  background-color: #ff5928 !important;
  border-radius: 5px !important;
  margin-bottom: 10px !important;
}

.modalStyles1 {
  background: #11de8a;
  border-radius: 10px !important;
}

.leftImage {
  top: 1px;
  right: 16px;
  width: 47px;
  height: 49px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
  position: absolute;
}

.uploadImage2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 35px;
  background: rgba(17, 222, 138, 0.1);
  border: 0.25px solid #11DE8A;
  border-radius: 15px;
  margin-top: 8px;
  position: relative;
  padding: 0 5px;
}

.uploadImage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* width: 130px; */
  height: 35px;
  background: rgba(17, 222, 138, 0.1);
  border: 0.25px solid #11DE8A;
  border-radius: 15px;
  margin-top: 8px;
  position: relative;
  padding: 0 10px;
  margin: 0 10px;
}

.innerUploadImg {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 15px;
  top: -15px;
  border-radius: 10px;
}

.uploadImage1 {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  width: 230px !important;
  height: 40px !important;
  background-color: rgba(17, 222, 138, 0.05) !important;
  /* border: 0.25px solid #11DE8A; */
  border-radius: 10px !important;
  margin-top: 8px !important;
  position: relative !important;
}

.uploadImage1:hover {
  background-color: rgba(18, 222, 138, 0.07) !important;
}

input {
  color: black !important;
}

.custom-cards-inputs {
  border: none !important;
  box-shadow: 0px 0px 7px 0px rgb(193 193 193 / 3%) !important;
}

input[type=time]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  /* background-color: white; */
  z-index: 1;
  position: relative;
  transform: translateX(0px);
  height: 25px !important;
  width: 25px !important;
  /* border-radius: 10px; */
  /* border: 1px solid #abafb3; */
  /* font-size: 50px !important; */
}

input[type=date]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  /* background-color: white; */
  z-index: 1;
  position: relative;
  transform: translateX(0px);
  height: 25px;
  width: 25px;
  /* border-radius: 10px; */
  /* border: 1px solid #abafb3; */
  /* font-size: 50px !important; */
}

input[type="select"]::-webkit-inner-spin-button {
  color: rgba(0, 0, 0, 0);
  /* background-color: white; */
  z-index: 1;
  position: relative;
  transform: translateX(0px);
  height: 25px;
  width: 25px;
  /* border-radius: 10px; */
  /* border: 1px solid #abafb3; */
  /* font-size: 50px !important; */
}

.leftImage2 {
  top: 1px;
  right: 1px;
  width: 55px;
  height: 49px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
  position: absolute;
  background-color: white;
}

td {
  padding: 20px 15px !important;
}

.table-responsive {
  overflow: auto !important;
}

@media screen and (max-width:1150px) {
  .table-responsive {
    overflow-x: scroll !important;
  }

  .table-responsive table {
    width: 1050px !important;
  }

}

@media (max-width:768px) {
  .imgPosition1 {
    left: 34%;
  }

}

@media (max-width:640px) {
  .imgPosition1 {
    left: 29%;
  }

}

@media (max-width:500px) {
  .imgPosition1 {
    left: 26%;
  }
  .card-header{
    padding: 10px !important;
  }
  .pinText1 {
    padding: 0 0px !important;
  }

}

@media (max-width:450px) {
  .imgPosition1 {
    left: 21%;
  }

}

@media (max-width:400px) {
  .imgPosition1 {
    left: 15%;
  }

}

@media (max-width:300px) {
  .imgPosition1 {
    left: 5%;
  }

}