.logout-link {
    color: #b7f5dc !important;
}

.logout-link:hover {
    color: rgb(255, 255, 255) !important;
}

.Settingarrow {
    transform: rotate(180deg)
}

.content {
    min-height: calc(100vh - 93px) !important;
}